<template>
    <div class="marketManagement">
        <div class="mm-title">
            <div class="mt-title">销售管理</div>
            <div class="mt-add">
                <div class="type">
                    <div class="tag">销售类型</div>
                    <el-select @change="changeSelect" v-model="marketType" placeholder="请选择">
                        <el-option
                        v-for="item in marketOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="add" @click="clickAddBtn">创建销售人员</div>
            </div>
        </div>
        <div class="mm-content">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="联系方式"></el-table-column>
                <el-table-column prop="type" label="销售类型"></el-table-column>
                <el-table-column label="推荐码">
                    <template slot-scope="scope">
                        <div class="code">{{scope.row.referral_code}}</div>
                    </template>
                </el-table-column>
                <el-table-column  width="140">
                    <template slot-scope="scope">
                        <div class="btn-wrap">
                            <div class="btn" @click="clickEdit(scope.row)">编辑</div>
                            <div class="btn" @click="clickDel(scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加 -->
        <el-dialog
            :visible.sync="isShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="class-wrap">
                <div class="cw-title">{{isShowMD?'编辑销售人员':'创建销售人员'}}</div>
                <div class="cw-list">
                    <div class="item">
                        <el-input v-model="marketName" placeholder="请输入销售人员名称"></el-input>
                    </div>
                    <div class="item">
                        <el-input v-model="phone" placeholder="请输入联系方式"></el-input>
                    </div>
                    <div class="item">
                        <el-select v-model="selectMarket" placeholder="请选择销售类型">
                            <el-option
                                v-for="item in selectMarketOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">确定在销售列表中删除【{{delName}}】?</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'marketManagement',
            marketOptions: [],
            selectMarketOptions: [],
            marketType: '',
            selectMarket: '',
            tableData: [],
            isShow: false,
            isShowMD: false,
            isDel: false,
            phone: '',
            marketName: '',
            delName: '',
            page: 1,
            page_size: 100,
            type_id: '',
            editId: ''
        }
    },
    mounted () {
        this.saleTypeWhole()
        this.getSaleList()
    },
    methods: {
        // 选择类型
        changeSelect(){
            this.type_id = this.marketType
            this.getSaleList()
        },
        // 获取销售
        getSaleList(){
            let params = {
                page_num: this.page,
                page_size: this.page_size,
                type_id: this.type_id
            }
            api.get('/service/sale/get_sale_list', params, (res) =>  {
                this.tableData = res.data.list
            });
        },
        // 获取销售类型
        saleTypeWhole(){
            api.get('/service/sale/get_sale_type_whole', {}, (res) =>  {
                this.selectMarketOptions = res.data.list
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.marketOptions = resultSecond
            });
        },
        // 编辑
        clickEdit(row){
            this.isShow = true
            this.marketName = row.name;
            this.phone = row.phone;
            this.selectMarket = row.type_id;
            this.editId = row.id;
            this.isShowMD = true
        },
        // 删除
        clickDel(row){
            this.isDel = true;
            this.delName = row.name
            this.editId = row.id;
        },
        // 确定删除
        clickConfirm(){
            let params = {
                id: this.editId,
            }
            api.post('/service/sale/sale_del', params, (res) =>  {
                this.$message.success('已删除')
                this.getSaleList()
                this.isDel = false
            });
        },
        // 创建销售人员
        clickAddBtn(){
            this.isShow = true
            this.isShowMD = false
            this.marketName = '';
            this.phone = ''
            this.selectMarket = '';
        },
        // 确定添加
        clickAddMain(){
            if(this.marketName == ''){
                this.$message.error('请输入销售人员名称')
                return
            }
            if(this.phone == ''){
                this.$message.error('请输入联系方式')
                return
            }
            if(this.selectMarket == ''){
                this.$message.error('请选择销售类型')
                return
            }
            if(this.isShowMD){
                // 编辑
                let params = {
                    id: this.editId,
                    name: this.marketName,
                    phone: this.phone,
                    type_id: this.selectMarket,
                }
                api.post('/service/sale/sale_edit', params, (res) =>  {
                    this.$message.success('已编辑')
                    this.getSaleList()
                    this.isShow = false
                });
            }else{
                // 添加
                let params = {
                    name: this.marketName,
                    phone: this.phone,
                    type_id: this.selectMarket,
                }
                api.post('/service/sale/sale_add', params, (res) =>  {
                    this.$message.success('已添加')
                    this.getSaleList()
                    this.isShow = false
                });
            }
        }
    },
}
</script>

<style lang='scss'>
.marketManagement{
    .mm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .type{
                margin-right: 30px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .tag{
                    font-size: 16px;
                    color: #101010;
                    margin-right: 15px;
                }
                .el-select{
                    width: 130px;
                }
            }
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .mm-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                padding: 0 15px;
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px;
            .item{
                margin-bottom: 15px;
                .el-select{
                    width: 100%;
                }
            }
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
</style>
